<template>
	<div class="">
		<el-dialog :close-on-click-modal="false" v-loading="listLoading"
			:visible.sync="isAddBindingDoctorHousekeepingVisible" width="800px" :before-close="handleCancel">
			<!--<span slot="title" style="font-size: 14px;color: #333;font-weight: 700;">档案</span>-->

			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" size="mini" label-position="top"
				label-width="100px" class="ruleForm">

				<div class="required mgb-5"></div>
				<span>档案</span>
				<div style="display: flex; justify-content: space-between; align-items: center">
					<el-form-item label="查询患者" style="margin-bottom: 10px">
						<el-input class="form-overlay-input-select" placeholder="请输入客户姓名或者手机号"
							prefix-icon="el-icon-search" v-model="searchParams.keywords"
							@input="_getPatientRecordsList">
						</el-input>
					</el-form-item>
					<el-form-item label="选择患者" prop="patient" style="margin-bottom: 10px">
						<el-select v-model="ruleForm.patient" placeholder="请选择客户姓名或者手机号"
							@change="handlePatientChangeSelect">
							<el-option v-for="item in patientRecordsList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</div>


				<div class="patient-info-container" style="margin-bottom: 20px">
					<div class="patient-item">档案编号：
						<span>{{ruleForm.patient && JSON.parse(ruleForm.patient).id}}</span>
					</div>
					<div class="patient-item">会员ID：
						<span>{{ruleForm.patient && JSON.parse(ruleForm.patient).member_id}}</span>
					</div>
					<div class="patient-item">客情姓名：
						<span>{{ruleForm.patient && JSON.parse(ruleForm.patient).staff_name}}</span>
					</div>
					<div class="patient-item">手机号：
						<span>{{ruleForm.patient && JSON.parse(ruleForm.patient).staff_mobile}}</span>
					</div>
					<div class="patient-item patient-item-last">平台：
						<span>{{ruleForm.patient && JSON.parse(ruleForm.patient).agent_name}}</span>
					</div>
				</div>

				<div class="required mgb-5"></div>
				<span>客情</span>
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="姓名" prop="" style="margin-bottom: 10px">
						<el-input v-model="ruleForm.staff_name" placeholder="" disabled></el-input>
					</el-form-item>
					<el-form-item label="工号" prop="" style="margin-bottom: 10px">
						<el-input v-model="ruleForm.ad_expand" placeholder="" disabled></el-input>
					</el-form-item>
				</div>
				<el-form-item label="手机号" prop="" style="margin-bottom: 20px">
					<el-input v-model="ruleForm.mobile" placeholder="" disabled></el-input>
				</el-form-item>


				<div class="required mgb-5"></div>
				<span>医生</span>
				<el-form-item label="选择医生" prop="doctor" style="margin-bottom: 10px">
					<el-select v-model="ruleForm.doctor" filterable placeholder="请输入医生姓名或编号"
						@change="handleDoctorChangeSelect">
						<el-option v-for="item in doctorList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<div class="doctor-info-container" style="margin-bottom: 40px">
					<div class="doctor-item">医生编号：
						<span>{{ruleForm.doctor && JSON.parse(ruleForm.doctor).doc_code}}</span>
					</div>
					<div class="doctor-item">医生姓名：
						<span>{{ruleForm.doctor && JSON.parse(ruleForm.doctor).doctor_name}}</span>
					</div>
					<div class="doctor-item">医生职称：
						<span>{{ruleForm.doctor && JSON.parse(ruleForm.doctor).professionalName}}</span>
					</div>
				</div>

				<!--				<div class="required mgb-5"></div>
				<span>服务包</span>
				<el-form-item label="套餐包" prop="doctor" style="margin-bottom: 10px">
					<el-select v-model="ruleForm.demand"
										 filterable
										 placeholder="请选择套餐包"
										 @change="handleDemandChangeSelect">
						<el-option
							v-for="item in demandList"
							:key="item.id"
							:label="item.title"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="内容" style="margin-bottom: 10px">
						<el-input v-model="ruleForm.demand.content" placeholder="" disabled></el-input>
					</el-form-item>
					<el-form-item label="服务天数" style="margin-bottom: 10px" >
						<el-input v-model="ruleForm.demand.expired_days" placeholder="" disabled></el-input>
					</el-form-item>
					<el-form-item label="服务金额" prop="" style="margin-bottom: 20px" >
						<el-input v-model="ruleForm.demand.price" placeholder="" disabled></el-input>
					</el-form-item>
				</div>-->
			</el-form>


			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="handleCancel">
					<span style="font-size: 12px">取 消</span>
				</el-button>
				<el-button style="margin-left: 20px;background-color: #2362FB; border-color: #2362FB" size="mini"
					type="primary" @click="submitForm('ruleForm')">
					<!--<i class="el-icon-edit" style="font-size: 13px"/>-->
					<span style="font-size: 12px">保 存</span>
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getButlerPatientRecordsList,
		getButlerDoctorList,
		postButlerBind
	} from '@/api/cdm/cdm-housekeeper.js'

	export default {
		name: "AddBindingDoctorHousekeeping",
		props: {
			isAddBindingDoctorHousekeepingVisible: {
				type: Boolean,
				default: false
			}
		},
		created() {
			this._getPatientRecordsList()
			this._getDoctorsList()
		},
		data() {
			return {

				listLoading: false,
				searchParams: {
					keywords: ''
				},
				patientRecordsList: [],
				doctorList: [],
				demandList: [],
				ruleForm: {
					patient: '',
					real_name: '',
					ad_expand: '',
					mobile: '',
					doctor: '',
					demand: {},
					content: '',
					serviceTime: '',
					servicePrice: ''
				},
				rules: {
					patient: [{
						required: true,
						message: '请选择患者',
						trigger: 'blur'
					}],
					real_name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					ad_expand: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					doctor: [{
						required: true,
						message: '请选择医生',
						trigger: 'blur'
					}],
					demand: [{
						required: true,
						message: '请选择套餐包',
						trigger: 'blur'
					}]
				},
				testList: []
			}
		},
		methods: {
			_getPatientRecordsList(rest) {
				if (rest === 'restPage') {
					this.listQuery.page = 1
				}
				// this.listLoading = true
				let params = {
					...this.searchParams
				}
				getButlerPatientRecordsList(params).then(response => {
					let data = response
					if (data.code == 200) {
						this.patientRecordsList = data.data
						this.patientRecordsList = this.patientRecordsList.map(item => {
							return {
								value: JSON.stringify(item),
								label: item.real_name + ' ' + item.mobile
							}
						})
					}
					this.listLoading = false
				}).catch(error => {
					console.log(error);
					this.listLoading = false
				})
			},
			_getDoctorsList(rest) {
				console.log('rest',rest)
				if (rest === 'restPage') {
					this.listQuery.page = 1
				}
				this.listLoading = true
				let params = {
					...this.searchParams
				}
				getButlerDoctorList(params).then(response => {
					let data = response
					if (data.code == 200) {
						this.doctorList = data.data
						this.doctorList = this.doctorList.map(item => {
							return {
								value: JSON.stringify(item),
								label: item.doctor_name,
							}
						})
					}
					this.listLoading = false
				}).catch(error => {
					console.log(error);
					this.listLoading = false
				})
			},
			_postButlerBind() {
				this.listLoading = true
				const data = {
					con_id: JSON.parse(this.ruleForm.patient).id.toString(),
					doctor_id: JSON.parse(this.ruleForm.doctor).id.toString(),
				}
				postButlerBind(data).then(response => {
					let data = response
					if (data.code == 200) {
						this.$message({
							showClose: true,
							message: '绑定成功',
							type: 'success'
						})
						this.$emit('getList')
						this.handleConfirm()
					} else {
						this.$message({
							showClose: true,
							message: data.message || data.msg,
							type: 'error'
						})
					}
					this.listLoading = false
				}).catch(error => {
					this.$message({
						showClose: true,
						message: '网络错误',
						type: 'success'
					})
					this.listLoading = false
				})
			},

			handlePatientChangeSelect(value) {
				console.log(value)
				this.ruleForm.staff_name = JSON.parse(value).staff_name
				this.ruleForm.ad_expand = JSON.parse(value).ad_expand
				this.ruleForm.mobile = JSON.parse(value).mobile
			},
			handleDoctorChangeSelect(value) {
				console.log(value)
				this.ruleForm.demand = ''
				this.demandList = JSON.parse(value).demand
			},
			handleDemandChangeSelect(value) {
				console.log(value)
				this.ruleForm.demand = this.demandList.filter(item => item.id == value)[0]
				console.log(this.ruleForm.demand)
			},
			submitForm(formName) {
				console.log(this.$refs[formName])
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this._postButlerBind()
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			handleConfirm() {
				this.resetForm('ruleForm')
				this.$emit('update:isAddBindingDoctorHousekeepingVisible', false)
			},
			handleCancel(e) {
				this.resetForm('ruleForm')
				this.$emit('update:isAddBindingDoctorHousekeepingVisible', false)
			},
		}
	}
</script>

<style scoped lang="scss">
	/* 蓝色方块 */
	.required {
		margin-top: 2px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}

	.required+span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}

	.mgb-5 {
		margin-bottom: 5px;
	}

	.mgb-10 {
		margin-bottom: 10px;
	}

	.mgb-20 {
		margin-bottom: 20px;
	}

	/deep/ .el-dialog__header {
		padding-top: 10px;
	}

	.patient-info-container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.patient-item {
			font-size: 14px;
			color: #888;
			font-weight: bolder;

			span {
				font-size: 14px;
				font-weight: normal;
				color: #333;
			}
		}

		.patient-item-last {
			// margin-left: auto;
		}
	}

	.doctor-info-container {
		width: 500px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.doctor-item {
			font-size: 14px;
			color: #888;
			font-weight: bolder;

			span {
				font-size: 14px;
				font-weight: normal;
				color: #333;
			}
		}
	}

	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}

	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}


	/*弹出框表单配置*/
	/deep/ .el-form-item__label {
		padding-bottom: 0;
	}

	/deep/ .el-form-item__content {
		width: 360px;
	}

	.from-item-second {
		margin-left: 0;
		margin-right: 20px;
	}

	/deep/ .from-item-third {
		.el-form-item__content {
			width: 590px;
		}
	}

	/deep/ .el-cascader {
		width: 360px;
	}

	/deep/ .el-select {
		width: 360px !important;
	}

	/deep/ .el-date-editor {
		width: 360px !important;
	}

	/*select 宽度配置*/
	/deep/ .el-select {
		width: 85px;
	}

	.form-overlay-input-select {
		/*/deep/.el-input__inner{*/
		/*	border-bottom-right-radius: 0;*/
		/*	border-top-right-radius: 0;*/
		/*	border-right: 0;*/
		/*}*/
	}
</style>
