/*
 *慢病管理-医管家接口
 */

import request from '@/utils/request'

/*
 *********绑定记录********
 */

// 套餐包列表
export function getButlerList(params) {
	return request({
		url: '/admin/butler/list',
		method: 'GET',
		params
	})
}

// 患者档案列表
export function getButlerPatientRecordsList(params) {
	return request({
		url: '/admin/butler/contractList',
		method: 'GET',
		params
	})
}

// 绑定套餐包
export function postButlerBind(data) {
	return request({
		url: '/admin/butler/bind',
		method: 'POST',
		data
	})
}

// 解绑定套餐包
export function putButlerUnbind(id, data) {
	return request({
		url: `/admin/butler/unBoundButler/${id}`,
		method: 'PUT',
		data
	})
}

// 套餐包绑定统计记录
export function getButlerCount(params) {
	return request({
		url: '/admin/butler/count',
		method: 'GET',
		params
	})
}


/*
 *********健康档案********
 */

// 健康套餐列表
export function getButlerDemandList(params) {
  return request({
    url: '/admin/butler/demandList',
    method: 'GET',
    params
  })
}

// 医师列表
export function getButlerDoctorList(params) {
  return request({
    url: '/admin/butler/docList',
    method: 'GET',
    params
  })
}

// 添加健康套餐
export function postButlerAddDemand(data) {
  return request({
    url: '/admin/butler/addDemand',
    method: 'POST',
    data
  })
}

// 修改健康套餐
export function putButlerUpdateDemand(id, data) {
  return request({
    url: `/admin/butler/updateDemand/${id}`,
    method: 'PUT',
    data
  })
}

// 删除健康套餐
export function delButlerDelDemand(id, data) {
  return request({
    url: `/admin/butler/delDemand/${id}`,
    method: 'DELETE',
    data
  })
}
//合并档案

export function postMergeContractM(data) {
  return request({
    url: `/admin/mergeContractM`,
    method: 'post',
    data
  })
}
