<template>
	<div class="">

		<div class="gg-container">
			<div class="search-container">
				<div class="activate-unactivated-container">
					<div class="activate-wrapper">
						<i class="el-icon-warning"></i>
						<span>未激活：{{unActivePer}}% ({{unActiveCount}}人)</span>
					</div>
					<div class="unactivated-wrapper">
						<i class="el-icon-success"></i>
						<span>已激活：{{activedPer}}% ({{activedCount}}人)</span>
					</div>
				</div>
				<div class="search-container-fn-input" style="margin-left: auto;">
					<el-input size="small" placeholder="请输入患者姓名" prefix-icon="el-icon-search" clearable
						@change="getList" v-model="searchParams.keywords">
					</el-input>
					<!--<el-button size="mini" type="primary"-->
					<!--	style="margin-left: 20px; padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"-->
					<!--	@click="handleBindingHousekeeper">-->
					<!--	<i class="iconfont icon-wode-bangdingguanxi" style="font-size: 13px" />-->
					<!--	<span style="margin-left: 5px; font-size: 12px">绑定管家</span>-->
					<!--</el-button>-->
				</div>
			</div>


			<!--table-->
			<el-table ref="refTable" :header-cell-style="{'text-align':'center'}" v-loading="listLoading" border
				:data="tableData" style="width: 100%; margin-top: 30px; z-index: 0">
				<el-table-column fixed label="ID" type="index" align="center" width="120">
				</el-table-column>
				<el-table-column label="档案信息" width="220" align="center">
					<template slot-scope="scope">
						<div>
							<span>平台</span>
							<span>{{scope.row.agent_name}}</span>
						</div>
						<div>
							<span>手机号</span>
							<span>{{scope.row.mobile}}</span>
						</div>
						<div>
							<span>档案编号：</span>
							<span>{{scope.row.con_id}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="服务医生" width="220" align="center">
					<template slot-scope="scope">
						<div>
							<span>姓名：</span>
							<span>{{scope.row.doctor_name}}</span>
						</div>
						<div>
							<span>编号：</span>
							<span>{{scope.row.doc_code}}</span>
						</div>
						<div>
							<span>职称：</span>
							<span>{{scope.row.professional_name}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="服务客情" width="220" align="center">
					<template slot-scope="scope">
						<div>
							<span>姓名：</span>
							<span>{{scope.row.assistant_name}}</span>
						</div>
						<div>
							<span>工号：</span>
							<span>{{scope.row.ad_expand}}</span>
						</div>
						<div>
							<span>手机号：</span>
							<span>{{scope.row.assistant_phone}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="服务患者" width="220" align="center">
					<template slot-scope="scope">
						<div>
							<span>服务患者：</span>
							<span>{{scope.row.pat_name}}</span>
						</div>
						<div>
							<span>患者ID：</span>
							<span>{{scope.row.pat_id}}</span>
						</div>
						<div>
							<span>会员ID：</span>
							<span>{{scope.row.member_id}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="激活信息" width="240" align="center">
					<template slot-scope="scope">
						<div>
							<span>激活状态：</span>
							<span>{{scope.row.confirm_status ? '已激活' : '未激活'}}</span>
						</div>
						<div>
							<span>激活时间：</span>
							<span>{{scope.row.activated_at}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="from" align="center" label="来源" width="220">
					<template slot-scope="scope">
						<span>{{scope.row.from === 1 ? '自助购买' : '客情绑定'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="created_at" align="center" label="创建时间" width="220">
				</el-table-column>
				<!-- <el-table-column align="center" width="280" label="操作" fixed="right">
					<template slot-scope="scope">
						<el-button size="mini" type="success" icon="el-icon-upload2"
							style="margin-right: 10px; padding: 7px 8px;margin-left: 5px"
							@click="handleServiceCondition(scope.$index, scope.row)">使用情况
						</el-button>
						<el-button size="mini" type="primary" icon="el-icon-edit" style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;
				             background-color: #2362FB; border-color: #2362FB"
							@click="handleBindingHousekeeperRow(scope.$index, scope.row)">解绑套餐包
						</el-button>
					</template>
				</el-table-column> -->
			</el-table>

			<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
				@pagination="getList" />
		</div>

		<!--添加绑定管家-->
		<add-binding-doctor-housekeeping v-if="isAddBindingDoctorHousekeepingVisible"
			:isAddBindingDoctorHousekeepingVisible.sync="isAddBindingDoctorHousekeepingVisible" @getList="getList">
		</add-binding-doctor-housekeeping>

	</div>
</template>

<script>
	import Pagination from '@/components/Pagination'
	import AddBindingDoctorHousekeeping from './components/AddBindingDoctorHousekeeping';
	import {
		getButlerList,
		getButlerCount,
		putButlerUnbind
	} from '@/api/cdm/cdm-housekeeper.js'

	export default {
		name: "BindingRecord",
		components: {
			Pagination,
			AddBindingDoctorHousekeeping
		},
		created() {
			this.getList()
			this._getButlerCount()
		},
		data() {
			return {
				unActiveCount: '', // 未激活人数
				unActivePer: '', // 未激活比例
				activedCount: '', // 已激活人数
				activedPer: '', // 已激活比例
				searchParams: {
					keywords: ''
				},
				listQuery: {
					page: 1,
					limit: 10,
					importance: undefined,
					title: undefined,
					type: undefined,
					sort: '+id',
				},
				total: 0,
				listLoading: false,
				tableData: [],
				isAddBindingDoctorHousekeepingVisible: false
			}
		},
		methods: {
			getList(rest) {
				if (rest === 'restPage') {
					this.listQuery.page = 1
				}
				this.listLoading = true
				let params = {
					...this.searchParams
				}
				params.page = this.listQuery.page
				params.page_size = this.listQuery.limit
				getButlerList(params).then(response => {
					let data = response
					if (data.code === 200) {
						let _data = data.data
						this.total = _data.total
						this.listQuery.limit = Number(_data.per_page)
						this.tableData = _data.list
					}
					this.listLoading = false
				}).catch(error => {
					console.log(error);
					this.listLoading = false
				})
			},
			_getButlerCount() {
				getButlerCount().then(response => {
					if (response.code == 200) {
						console.log(response)
						const data = response.data
						this.unActiveCount = data.unActiveCount // 未激活人数
						this.unActivePer = data.unActivePer // 未激活比例
						this.activedCount = data.activedCount // 已激活人数
						this.activedPer = data.activedPer // 已激活比例
					} else {
						this.$message({
							message: response.msg,
							type: 'warning'
						})
					}
				}).catch(error => {
					this.$message({
						message: '网络错误',
						type: 'error'
					})
				})
			},
			handleBindingHousekeeper() {
				this.isAddBindingDoctorHousekeepingVisible = true
			},
			handleServiceCondition(index, row) {

			},
			handleBindingHousekeeperRow(index, row) {
				putButlerUnbind(row.id).then(response => {
					if (response.code == 200) {
						console.log(response.data)
					}
				}).catch(error => {
					console.log(error)
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.header {
		display: flex;
		align-items: center;
		height: 26px;
		margin-bottom: 10px;

		i {
			width: 25px;
			height: 25px;
			background-color: #2632fb;
			color: white;
			line-height: 25px;
			text-align: center;
			margin-right: 8px;
			border-radius: 2px;
		}
	}

	/*激活和未激活样式*/
	.activate-unactivated-container {
		display: flex;

		.activate-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			min-width: 180px;
			background-color: #FCC810;
			border-radius: 5px;

			i {
				padding-top: 1px;
				color: #fff;
				font-size: 14px;
			}

			span {
				color: #fff;
				font-weight: bolder;
				margin-left: 3px;
				font-size: 14px;
			}
		}

		.unactivated-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 25px;
			height: 50px;
			min-width: 180px;
			background-color: #4EBBFC;
			border-radius: 5px;

			i {
				padding-top: 1px;
				color: #fff;
				font-size: 14px;
			}

			span {
				color: #fff;
				font-weight: bolder;
				margin-left: 3px;
				font-size: 14px;
			}
		}
	}
</style>
